<template>
  <div ref="line" class="line"></div>
</template>

<script>
export default {
  name: 'LineCharts',
  props: ['monthData'],
  data() {
    return {
      myChart: null
    }
  },
  watch: {
    monthData: {
      handler(newVal, oldVal) {
        this.myChart.setOption({
          title: {
            // 主标题
            text: '过去30天完成工单数',
            // 子标题
            subtext: `${this.monthData.allStatic} 单`,
            // 主标题的颜色
            textStyle: { color: '#fff', fontSize: '14', fontWeight: '400' },
            // 标题的位置
            left: 'left'
          },
          // 鼠标经过提示信息
          tooltip: {
            trigger: 'axis',
            textStyle: { color: '#1B6FEE' }
          },
          xAxis: {
            show: false,
            boundaryGap: false,
            type: 'category',
            data: this.monthData.static.day
            // axisLabel: {
            //   interval: 1,
            //   showMinLabel: true,
            //   showMaxLabel: true
            // }
          },
          yAxis: {
            show: true,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgba(255, 255, 255, 0.2)'
              }
            }
          },
          series: [
            {
              type: 'line',
              smooth: true,
              showSymbol: false,
              data: this.monthData.static.data,
              // 折线拐点样式
              // itemStyle: {
              //   opacity: 0
              // },
              // 折线的样式
              lineStyle: {
                color: '#00A3FF'
              },
              // 区域填充颜色
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(0, 163, 255, 0.5)' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(0, 163, 255, 0)' // 100% 处的颜色
                    }
                  ],
                  global: false // 缺省为 false
                }
              }
            }
          ],
          grid: {
            left: '2%',
            right: '0%',
            top: '40%',
            bottom: '0%'
          }
        })
      }
    }
  },
  mounted() {
    this.myChart = this.$echarts.init(this.$refs.line)

    this.myChart.setOption({
      title: {
        // 主标题
        text: '过去30天完成工单数',
        // 子标题
        subtext: `${this.monthData.allStatic || 0} 单`,
        // 主标题的颜色
        textStyle: { color: '#fff', fontSize: '14', fontWeight: '400' },
        // 标题的位置
        left: 'left'
      },
      // 鼠标经过提示信息
      tooltip: {
        trigger: 'axis',
        textStyle: { color: '#1B6FEE' }
      },
      xAxis: {
        show: false,
        boundaryGap: false,
        type: 'category',
        data: [1, 2, 3, 4],
        axisLabel: {
          interval: 1,
          showMinLabel: true,
          showMaxLabel: true,
          rotate: '45'
        }
      },
      yAxis: {
        show: true,
        interval: 1,
        splitLine: {
          show: true,
          lineStyle: {
            type: 'solid',
            color: 'rgba(255, 255, 255, 0.2)'
          }
        }
      },
      series: [
        {
          type: 'line',
          smooth: true,
          showSymbol: false,
          data: [0,5, 3,4],
          // 折线拐点样式
          // itemStyle: {
          //   opacity: 0
          // },
          // 折线的样式
          lineStyle: {
            color: '#00A3FF'
          },
          // 区域填充颜色
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(0, 163, 255, 0.5)' // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(0, 163, 255, 0)' // 100% 处的颜色
                }
              ],
              global: false // 缺省为 false
            }
          }
        }
      ],
      grid: {
        left: '5%',
        right: '2%',
        top: '40%',
        bottom: '0%'
      }
    })
  },

  methods: {}
}
</script>

<style lang="css" scoped>
.line {
  width: 100%;
  height: 100%;
}
</style>
