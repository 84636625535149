export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

export function formatTime(time, option) {
  time = +time * 1000
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
  }
}

export const delEmptyQueryNodes = (obj = {}) => {
  Object.keys(obj).forEach(key => {
    let value = obj[key]
    value && typeof value === 'object' && delEmptyQueryNodes(value)

    if (value === '' || value === null || value === undefined) {
      // || Object.keys(value).length === 0 || value.length === 0
      delete obj[key]
    }
  })
  return obj
}

export const handleRoutes = routes => {
  for (let i = 0; i < routes.length; i++) {
    let item = routes[i]

    // if (item.component) {
    item.component = () => import(item.component)
    // }

    console.log(item, 'itemdddd')

    if (item.children && item.children.length > 0) {
      for (let i = 0; i < item.children.length; i++) {
        if (item.children[i].meta && !item.children[i].meta.hidden) {
          item.redirect = item.children[i].path
          break
        }
      }

      handleRoutes(item.children)
    }

    return routes
  }
}

export const formatRoutes = routerArr => {
  const arr = []
  let obj = {}
  routerArr.forEach(router => {
    const tmp = { ...router }
    let component = tmp?.component
    if (component) {
      tmp['component'] = resolve => require([component], resolve)
    }

    if (tmp.children && tmp.children.length > 0) {
      for (let i = 0; i < tmp.children.length; i++) {
        if (tmp.children[i] && tmp.children[i].meta && !tmp.children[i].meta.hidden) {
          tmp.redirect = tmp.children[i].path
          break
        }
      }
      tmp.children = formatRoutes(tmp.children)
      obj = router
      arr.push(obj)
    } else {
      arr.push(tmp)
    }
  })
  return arr
}

export function downloadImg(imgUrl, name = '二维码') {
  //根据url和名称生成blob
  let image = new Image()
  image.setAttribute('crossOrigin', 'anonymous')
  image.src = imgUrl
  image.onload = () => {
    let canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    let ctx = canvas.getContext('2d')
    ctx.drawImage(image, 0, 0, image.width, image.height)
    canvas.toBlob(blob => {
      let url = URL.createObjectURL(blob)
      let Link = document.createElement('a')
      Link.download = name
      Link.href = url
      Link.click()
      Link.remove()
      // 用完释放URL对象
      URL.revokeObjectURL(url)
    })
  }
}
