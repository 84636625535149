<template>
  <div class="container">
    <div class="toilet-map">
      <Map
        ref="map"
        :defaultLng.sync="lng"
        :defaultLat.sync="lat"
        :boundsLat.sync="boundsLat"
        :boundsLng.sync="boundsLng"
        :toiletList="toiletList"
        :carList="carList"
        @getResultToiletList="getResultToiletList"
      ></Map>
    </div>
    <div class="left">
      <div class="left-top">
        <div class="title-mod">
          <div class="title-left">
            <i class="left-icon"></i>
            <span>{{ cityName }}改厕信息统计</span>
            <i class="right-icon"></i>
          </div>
          <div class="title-right">
            <dv-decoration-2 :dur="2" style="width:100%;height:5px" />
          </div>
        </div>

        <dv-border-box-8 :dur="4" style="max-height:250px">
          <div class="top-content">
            <div class="content-item">{{ '' }}</div>
            <div class="content-item">
              <span>改厕户数</span>
              <span>{{ totalCount.toiletSum }} 户</span>
            </div>
            <div class="content-item">
              <span>污粪处理站</span>
              <span>{{ totalCount.processing_station }} 座</span>
            </div>
            <div class="content-item">
              <span>维修服务站</span>
              <span>{{ totalCount.maintenance_point }} 座</span>
            </div>
            <div class="content-item">
              <span>吸污车</span>
              <span>{{ totalCount.carSum }} 辆</span>
            </div>
            <div class="content-item">
              <span>抽厕员</span>
              <span>{{ totalCount.toilet_extractor }} 人</span>
            </div>
            <div class="content-item">
              <span>维修员</span>
              <span>{{ totalCount.maintenance_user }} 人</span>
            </div>
          </div>
        </dv-border-box-8>
      </div>
      <div class="left-center">
        <div class="title-mod">
          <div class="title-left">
            <i class="left-icon"></i>
            <span>辖区实时工单信息</span>
            <i class="right-icon"></i>
          </div>
          <div class="title-right">
            <dv-decoration-2 :dur="2" style="width:100%;height:5px" />
          </div>
        </div>
        <div>
          <dv-scroll-board :config="areaList" style="width:100%;height:220px;margin:30px 0" />
        </div>
      </div>
      <div class="left-bottom">
        <div class="title-mod">
          <div class="title-left">
            <i class="left-icon"></i>
            <span>数据看板</span>
            <i class="right-icon"></i>
          </div>
          <div class="title-right">
            <dv-decoration-2 :dur="2" style="width:100%;height:5px" />
          </div>
        </div>
        <div class="line-chart">
          <LineChart :monthData="monthData"></LineChart>
        </div>
      </div>
    </div>
    <div class="header">
      <!-- <div></div>
      <img @click="fscreen" src="@/assets/full.png" alt="" /> -->
    </div>
    <div class="right">
      <div class="title-mod">
        <div class="title-left">
          <i class="left-icon"></i>
          <span>可视范围内智能厕所</span>
          <i class="right-icon"></i>
        </div>
        <div class="title-right">
          <dv-decoration-2 :dur="2" style="width:100%;height:5px" />
        </div>
      </div>
      <div class="right-count">
        <div class="count-total">共计：{{ part_toilet.length || 0 }}个</div>
        <div class="count-status">
          <div class="status-normal">
            <span>正常：</span>
            <span>{{ normal_toilet }}个</span>
          </div>
          <div class="status-unnormal">
            <span>待抽取：</span>
            <span>{{ abnormal_toilet }}个</span>
          </div>
        </div>
      </div>
      <div class="toilet-container">
        <div
          :class="['toilet', i == curIndex ? 'border-green' : 'border-yellow']"
          v-for="(item, i) in part_toilet"
          :key="i"
          @click="handleClickToilet(i, item)"
        >
          <div class="toilet-item">{{ item.name }}</div>
          <div class="toilet-item">
            <span>地址</span>
            <span>{{ item.address_dsc }}</span>
          </div>
          <div class="toilet-item">
            <span>状态</span>
            <span :class="[item.status == 20 ? 'green' : 'yellow']">{{ item.status == 20 ? '正常' : '待抽取' }}</span>
          </div>
          <div class="toilet-item">
            <span>上次抽取时间</span>
            <span>{{ item.last_fouling_at }}</span>
          </div>
          <!-- <dv-border-box-8></dv-border-box-8> -->
        </div>
      </div>
    </div>
    <div class="btn-group">
      <div class="change-zoom">
        <img @click="handlerZoomUp" src="../../assets/zoomUp.png" alt="" />
        <img src="../../assets/line.png" alt="" />
        <img @click="handlerZoomDown" src="../../assets/zoomDown.png" alt="" />
      </div>
      <div class="change-full">
        <img @click="fscreen" src="../../assets/full.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { getCarListAPI, getOrderListAPI, getMonthCountAPI, getCarStaticAPI, getToiletListAPI } from './api'
import Map from './map-screen.vue'
import LineChart from './line-chart.vue'
import toiletIcon from '@/assets/toilet_icon.png'
import carIcon from '@/assets/car_icon.png'
export default {
  name: 'Index',
  components: { LineChart, Map },
  inject: ['reload'],
  data() {
    return {
      curIndex: -1,
      lat:  this.$route.query.lat||36.05189,
      lng: this.$route.query.lon ||114.62837,
      cityName: this.$route.params.cityName || '安阳市',
      isLoadingCar: 0,
      isLoadToilet: 0,
      areaList: {
        header: ['厕所名称', '工单状态', '生成时间'],
        data: [],
        headerBGC: 'rgba(27, 126, 242, 0.2)',
        evenRowBGC: '',
        oddRowBGC: 'rgba(27, 126, 242, 0.2)',
        headerHeight: '50',
        rowNum: 4
      },
      monthData: {},
      zoom: 13,
      toiletList: [],
      carList: [],
      totalCount: {},
      timerForCar: null,
      boundsLng: 0.3215255518,
      boundsLat: 0.08493774,
      resultToiletList: []
    }
  },
  computed: {
    part_toilet() {
      return this.resultToiletList.filter(toilet => {
        return Math.abs(toilet.lat - this.lat) <= this.boundsLat / 2 && Math.abs(toilet.lng - this.lng) <= this.boundsLng / 2
      })
    },
    // 能看到正常的厕所
    normal_toilet() {
      return this.resultToiletList.filter(toilet => toilet.status == 20).length || 0
    },
    // 待抽取
    abnormal_toilet() {
      return this.resultToiletList.filter(toilet => toilet.status == 10).length || 0
    }
  },
  mounted() {
    clearInterval(this.timerForCar)
    // this.handleTimer()
    // this.getCarList(36.05189,114.62837)
    this.getOrderList()
    this.getMonthCount()
    this.getCarStatic()
    // this.getToiletList(36.05189,114.62837)
  },
  watch: {},
  methods: {
    getResultToiletList(list) {
      console.log('获取厕所',list);
      this.resultToiletList=list
      // this.resultToiletList = list.filter(toilet => {
      //   return Math.abs(toilet.lat - this.lat) <= this.boundsLat / 2 && Math.abs(toilet.lng - this.lng) <= this.boundsLng / 2
      // })
    },
    handleClickToilet(i, item) {
      const { lat, lng, name, status } = item
      this.curIndex = i
      this.lat = lat 
      this.lng = lng
      this.$refs.map.zoom = 13
      let params = { lat, lng, name, status }
      this.$refs.map.changeCenter(params)
    },
    handleTimer() {
      this.timerForCar = setInterval(() => {
        this.getCarList()
      }, 30000)
    },
    fscreen() {
      var el = document.documentElement
      var isFullscreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen
      if (!isFullscreen) {
        (el.requestFullscreen && el.requestFullscreen()) ||
          (el.mozRequestFullScreen && el.mozRequestFullScreen()) ||
          (el.webkitRequestFullscreen && el.webkitRequestFullscreen()) ||
          (el.msRequestFullscreen && el.msRequestFullscreen())
      } else {
        //退出全屏
        document.exitFullscreen
          ? document.exitFullscreen()
          : document.mozCancelFullScreen
          ? document.mozCancelFullScreen()
          : document.webkitExitFullscreen
          ? document.webkitExitFullscreen()
          : ''
      }
    },
    handlerZoomUp() {
      this.$refs.map.zoom++
      // this.$refs.map.map.setZoom(this.$refs.map.zoom)
    },
    handlerZoomDown() {
      this.$refs.map.zoom--
      // this.$refs.map.map.setZoom(this.$refs.map.zoom)
    },
    async getCarList(lat, lng) {
      const params = { distance: 3000, lat, lng, page_size: 20, has_car_position: 1 }
      const { data } = await getCarListAPI(params)
      this.carList = data
    },
    async getOrderList() {
      const { data } = await getOrderListAPI()
      const res = data.map(item => {
        return [item.toilet_name || '', item.status_dsc || '', item.submit_at || '']
      })
      // this.$set(this.areaList, 'data', res)
      this.areaList = {
        header: ['厕所名称', '工单状态', '生成时间'],
        data: res,
        headerBGC: 'rgba(27, 126, 242, 0.2)',
        evenRowBGC: '',
        oddRowBGC: 'rgba(27, 126, 242, 0.2)',
        headerHeight: '50',
        rowNum: 4,
        columnWidth: ['120', '100', '150']
      }
    },
    async getMonthCount() {
      this.monthData = await getMonthCountAPI()
    },
    async getCarStatic() {
      this.totalCount = await getCarStaticAPI()
      this.isLoadingCar = 1
    },
    async getToiletList(lat, lng) {
      let params = { distance: 3000, lat, lng, page_size: 20, has_toilet_position: 1 }
      const { data } = await getToiletListAPI(params)
      this.toiletList = data
      this.isLoadToilet = 1
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  min-width: 1000px;
  height: 100vh;
  overflow: hidden;
  .toilet-map {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    // height: 100vh;
    width: 100%;
  }
  .header {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    height: 75px;
    width: 100vw;
    background: url('~@/assets/img/header.png') no-repeat;
    background-size: cover;
    text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      display: block;
      width: 25px;
      margin-right: 20px;
      margin-bottom: 20px;
      opacity: 0.7;
      cursor: pointer;
    }
  }
  .left {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 75px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 461px;
    height: 100%;
    background-image: linear-gradient(to right, #000, transparent);
    opacity: 0.9;
    min-width: 300px;
    padding-left: 88px;
    overflow-y: auto;
    max-height: 100vh;
    &::-webkit-scrollbar {
      width: 0px;
    }
    .left-top {
      // padding-left: 0.8rem;
      // position: absolute;
      // top: 75px;
      // left: 0;
      // width: 100%;
      // height: 300px;
      .top-content {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 250px;
        padding: 24px 35px;
        max-width: 255px;
        // line-height: 25px;
        margin: 20px 0;
        background: linear-gradient(90deg, rgba(27, 126, 242, 0.5) 0%, rgba(62, 171, 248, 0) 100%);
        .content-item {
          &:nth-child(1) {
            font-weight: 600;
            font-size: 0.225rem;
            color: rgba(255, 255, 255, 0.9);
          }
          &:nth-child(n + 2) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 0.175rem;
            color: rgba(255, 255, 255, 0.5);
            span {
              &:nth-child(2) {
                color: #fff;
              }
            }
          }
        }
      }
    }
    // .left-center {
    //   // padding-left: 0.8rem;
    //   // position: absolute;
    //   // left: 0;
    //   // top: 40%;
    //   // height: 300px;
    //   // transform: translateY(-50%);
    // }
    .left-bottom {
      // padding-left: 0.8rem;
      // position: absolute;
      // left: 0;
      // bottom: 0;
      width: 100%;
      .line-chart {
        width: 100%;
        min-width: 300px;
        height: 180px;
        margin: 20px 0;
      }
    }
  }
  .right {
    width: 405px;
    padding-top: 75px;
    padding-right: 88px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    height: 100%;
    background-image: linear-gradient(to left, #000, transparent);
    opacity: 0.9;
    .right-count {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      padding: 20px 30px;
      .count-total {
        color: #00a3ff;
        margin-bottom: 10px;
      }
      .count-status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .status-normal {
          span {
            &:nth-child(1) {
              color: #ffffff;
            }
            &:nth-child(2) {
              color: #29f1fa;
            }
          }
        }
        .status-unnormal {
          span {
            &:nth-child(1) {
              color: #ffffff;
            }
            &:nth-child(2) {
              color: #f97238;
            }
          }
        }
      }
    }
    .toilet-container {
      overflow-y: auto;
      max-height: calc(100vh - 200px);
      &::-webkit-scrollbar {
        width: 0px;
      }
      .toilet {
        cursor: pointer;
        width: 100%;
        height: 156px;
        margin-bottom: 20px;
        padding: 24px 35px;
        background: linear-gradient(270deg, rgba(27, 126, 242, 0.6) 0%, rgba(62, 171, 248, 0) 100%);
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .toilet-item {
          // margin-bottom: 10px;
          &:nth-child(1) {
            font-weight: 600;
            font-size: 18px;
            color: rgba(255, 255, 255, 1);
          }
          &:nth-child(n + 2) {
            span {
              margin-right: 20px;
              font-size: 14px;
              &:nth-child(1) {
                color: rgba(255, 255, 255, 1);
              }
              &:nth-child(2) {
                color: #fff;
              }
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .content {
    z-index: 10;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 1rem;
    width: 100%;
    flex: 1;
    display: flex;
    color: #fff;
    .left {
      flex: 1;
      min-width: 300px;
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      // flex-direction: column;
      // background: url('../../assets/img/bg_left.png')no-repeat;
      .left-top {
        width: 100%;
        flex: 1;
        .top-content {
          // display: flex;
          // justify-content: space-between;
          // flex-direction: column;
          padding: 24px 35px;
          margin: 30px 0;
          max-width: 255px;
          line-height: 25px;
          background: linear-gradient(90deg, rgba(27, 126, 242, 0.4) 0%, rgba(62, 171, 248, 0) 100%);
          .content-item {
            &:nth-child(1) {
              font-weight: 600;
              font-size: 0.225rem;
              color: rgba(255, 255, 255, 0.9);
            }
            &:nth-child(n + 2) {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 0.175rem;
              color: rgba(255, 255, 255, 0.5);
              span {
                &:nth-child(2) {
                  color: #fff;
                }
              }
            }
          }
        }
      }
      .left-center {
        width: 100%;
        flex: 1;
      }
      .left-bottom {
        width: 100%;
        flex: 1;
        .line-chart {
          // width: 100%;
          height: 180px;
          margin: 20px 0;
        }
      }
    }

    .center {
      flex: 3;
      min-width: 720px;
      // background-color: #ccc;
    }
    .right {
      min-width: 300px;
      flex: 1;
      .right-count {
        font-weight: 600;
        font-size: 0.25rem;
        line-height: 0.35rem;
        padding: 20px 30px;
        .count-total {
          color: #00a3ff;
          margin-bottom: 10px;
        }
        .count-status {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .status-normal {
            span {
              &:nth-child(1) {
                color: #ffffff;
                opacity: 0.5;
              }
              &:nth-child(2) {
                color: #29f1fa;
              }
            }
          }
          .status-unnormal {
            span {
              &:nth-child(1) {
                color: #ffffff;
                opacity: 0.5;
              }
              &:nth-child(2) {
                color: #f97238;
              }
            }
          }
        }
      }
      .toilet-container {
        overflow-y: auto;
        max-height: 700px;
        &::-webkit-scrollbar {
          width: 2px;
        }
        .toilet {
          cursor: pointer;
          margin-bottom: 20px;
          padding: 20px 30px;
          background: linear-gradient(270deg, rgba(27, 126, 242, 0.4) 0%, rgba(62, 171, 248, 0) 100%);
          .toilet-item {
            margin-bottom: 10px;
            &:nth-child(1) {
              font-weight: 600;
              font-size: 0.225rem;
              line-height: 0.3125rem;
              color: rgba(255, 255, 255, 0.9);
            }
            &:nth-child(n + 2) {
              span {
                margin-right: 20px;
                font-size: 0.175rem;
                &:nth-child(1) {
                  color: rgba(255, 255, 255, 0.5);
                }
                &:nth-child(2) {
                  color: #fff;
                }
              }
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .title-mod {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      span {
        white-space: nowrap;
        text-shadow: 0px 2px 24px rgba(27, 126, 242, 0.91);
        font-weight: 500;
        font-size: 24px;
        text-shadow: 0px 2px 24px rgba(27, 126, 242, 0.91);
        margin: 0 10px;
      }
      .left-icon {
        width: 16px;
        height: 12px;
        background: url('../../assets/img/title_left_icon.png');
      }
      .right-icon {
        width: 16px;
        height: 12px;
        background: url('../../assets/img/title_right_icon.png');
      }
    }
    .title-right {
      flex: 1;
      margin-left: 20px;
    }
  }
  .yellow {
    color: #f97238 !important;
  }
  .green {
    color: #29f1fa !important;
  }
  .border-yellow {
    border-left: 6px solid #f97238;
  }
  .border-green {
    border-left: 6px solid #29f1fa;
  }
  .btn-group {
    z-index: 50;
    position: absolute;
    bottom: 55px;
    right: 463px;
    .change-zoom {
      padding: 10px 0;
      width: 48px;
      height: 96px;
      background: rgba(41, 241, 250, 0.1);
      border: 1px solid rgba(41, 241, 250, 0.4);
      margin-bottom: 8px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;
      img {
        cursor: pointer;
        width: 20px;
        height: 20px;
        &:nth-child(2) {
          height: 2px;
          width: 24px;
          cursor: default;
        }
        &:nth-child(3) {
          height: 2px;
        }
      }
    }
    .change-full {
      cursor: pointer;
      width: 48px;
      height: 48px;
      display: flex;
      background: rgba(41, 241, 250, 0.1);
      border: 1px solid rgba(41, 241, 250, 0.4);
      align-items: center;
      justify-content: center;
      img {
        cursor: pointer;
      }
    }
  }
}
</style>
