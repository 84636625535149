<template>
  <div class="map" ref="map"></div>
</template>

<script>
import { getCarListAPI, getToiletListAPI } from './api'
import toiletIcon from '@/assets/toilet_icon.png'
import carIcon from '@/assets/car_icon.png'
import _ from 'lodash'
export default {
  name: 'MapScreen',
  props: ['defaultLat', 'defaultLng', 'boundsLat', 'boundsLng'],
  data() {
    return {
      zoom: this.$route.query.zoom || 13,
      lat: this.$route.query.lat || 36.05189,
      lng: this.$route.query.lon || 114.62837,
      cityName: this.$route.query.cityName || '安阳市',
      map: null,
      toiletMarker: null,
      carMarker: null,
      timer: null,
      carList: [],
      toiletList: [],
      newLat: '',
      newLng: ''
    }
  },
  watch: {
    carList: {
      // deep: true,
      handler() {
        // this.initMap()
      }
    },
    toiletList: {
      deep: true,
      handler(newV, oldV) {}
    },
    zoom: {
      handler(newV, oldV) {
        this.map.setZoom(newV)
        const { _ne, _sw } = this.map.getBounds()
        this.$emit('update:boundsLat', _ne.lat - _sw.lat)
        this.$emit('update:boundsLng', _ne.lng - _sw.lng)
         this.$emit('getResultToiletList', this.toiletList)
      }
    }
  },
  created() {},
  mounted() {
    this.initMap()
    this.resetMarks(this.lat, this.lng)
  },
  computed: {},
  methods: {
    async resetMarks(lat, lng) {
      this.getCarList(lat, lng).then(() => {
        this.batchAddCarMarks()
      })
      this.getToiletList(lat, lng).then(() => {
        this.batchAddToiletMarks()
      })
    },
    async getCarList(lat, lng) {
      const params = { distance: 3000, lat, lng, page_size: 20, has_car_position: 1 }
      const { data } = await getCarListAPI(params)
      this.carList = data
    },
    async getToiletList(lat, lng) {
      let params = { distance: 3000, lat, lng, page_size: 20, has_toilet_position: 1 }
      const { data } = await getToiletListAPI(params)
      this.toiletList = data
      this.$emit('getResultToiletList', this.toiletList)
      // this.batchAddToiletMarks()
    },
    changeCenter(params) {
      const { lat, lng, status, name } = params
      this.map.setCenter(new TMap.LatLng(lat, lng))
      this.map.setZoom(this.zoom)
      this.infoWindow.open() //打开信息窗
      this.infoWindow.setPosition(new TMap.LatLng(lat, lng)) //设置信息窗位置
      this.infoWindow.setContent(
        `<div style="text-align:left;"><div style="font-size:18px;color:#00A3FF">${name}</div><div style="font-size:14px;color:#fff">状态：<span style="color:${
          status == 20 ? '#29F1FA' : '#FF7D00'
        }">${status == 20 ? '正常' : '待抽取'}</span></div></div>`
      )
      setTimeout(() => {
        this.infoWindow.close()
      }, 3000)
    },
    // 初始化
    initMap() {
      // 创建地图
      this.map = new TMap.Map(this.$refs.map, {
        center: new TMap.LatLng(this.lat, this.lng),
        zoom: this.zoom,
        mapStyleId: 'style2',
        // pitch: 45,
        showControl: false,
        scrollable: false,
        baseMap: {
          type: 'vector',
          features: ['base', 'building3d', 'building2d', 'arrow', 'label'] // 展示道路和底面、建筑物'base', 'road', 'point', 'building3d', 'label'
        }
      })
      // 创建marker
      this.toiletMarker = new TMap.MultiMarker({
        map: this.map, //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          toiletStyle: new TMap.MarkerStyle({
            width: 56, // 点标记样式宽度（像素）
            height: 56, // 点标记样式高度（像素）
            src: toiletIcon, //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 30, y: 32 }
          })
        }
        //点标记数据数组
        // geometries: [
        //   ...this.toiletList.map(item => {
        //     return {
        //       id: item.id,
        //       styleId: 'toiletStyle',
        //       position: new TMap.LatLng(item.lat, item.lng),
        //       properties: {
        //         //自定义属性
        //         name: item.name,
        //         status: item.status
        //       }
        //     }
        //   })
        // ]
      })
      this.carMarker = new TMap.MultiMarker({
        map: this.map, //指定地图容器
        //样式定义
        styles: {
          carStyle: new TMap.MarkerStyle({
            width: 64, // 点标记样式宽度（像素）
            height: 64, // 点标记样式高度（像素）
            src: carIcon, //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 }
          })
        }
        //点标记数据数组
        // geometries: [
        //   ...this.carList.map(item => {
        //     return {
        //       id: item.id,
        //       styleId: 'carStyle',
        //       position: new TMap.LatLng(item.lat, item.lng),
        //       properties: {
        //         //自定义属性
        //         plate_number: item.plate_number,
        //         driver_name: item.driver_name
        //       }
        //     }
        //   })
        // ]
      })
      // 创建信息框
      this.infoWindow = new TMap.InfoWindow({
        map: this.map,
        position: new TMap.LatLng(this.lat, this.lng), //设置信息框位置
        offset: { x: 10, y: -30 }, //设置信息窗相对position偏移像素
        enableCustom: true,
        content: '<div style="color:#fff;background: rgba(39, 46, 57, 0.7);padding:5px 10px;border:2px solid #00A3FF;border-radius:5px"></div>' //设置信息框内容
      })
      // 默认关闭信息框
      this.infoWindow.close()
      // 鼠标经过信息框
      // this.toiletMarker.on('mouseover', evt => {
      //   //设置infoWindow
      //   this.infoWindow.open() //打开信息窗
      //   this.infoWindow.setPosition(evt.geometry.position) //设置信息窗位置
      //   this.infoWindow.setContent(
      //     `<div style="text-align:left;"><div style="font-size:18px;color:#00A3FF">${
      //       evt.geometry.properties.name
      //     }</div><div style="font-size:14px;color:#fff">状态：<span style="color:${evt.geometry.properties.status == 20 ? '#29F1FA' : '#FF7D00'}">${
      //       evt.geometry.properties.status == 20 ? '正常' : '待抽取'
      //     }</span></div></div>`
      //   ) //设置信息窗内容
      // })
      // this.carMarker.on('mouseover', evt => {
      //   //设置infoWindow
      //   this.infoWindow.open() //打开信息窗
      //   this.infoWindow.setPosition(evt.geometry.position) //设置信息窗位置
      //   this.infoWindow.setContent(`<div><div style="font-size:16px">${evt.geometry.properties.plate_number}</div></div>`) //设置信息窗内容
      // })
      // // 鼠标离开marker关闭信息框
      // this.toiletMarker.on('mouseout', evt => {
      //   this.infoWindow.close()
      // })
      // this.carMarker.on('mouseout', evt => {
      //   this.infoWindow.close()
      // })

      this.map.on('dragend', e => {
        console.log('拖拽', this.map.getBounds())
        const { _ne, _sw } = this.map.getBounds()
        this.$emit('update:boundsLat', _ne.lat - _sw.lat)
        this.$emit('update:boundsLng', _ne.lng - _sw.lng)
        const { lat, lng } = this.map.getCenter()
        this.$emit('update:defaultLat', lat)
        this.$emit('update:defaultLng', lng)
        this.resetMarks(lat, lng)
        this.newLat = lat
        this.newLng = lng
      })
      this.map.on(
        'zoom',
        _.throttle(() => {
          const { _ne, _sw } = this.map.getBounds()
          this.$emit('update:boundsLat', _ne.lat - _sw.lat)
          this.$emit('update:boundsLng', _ne.lng - _sw.lng)
        }, 1000)
      )
    },
    batchAddCarMarks() {
      let geometries = [
        ...this.carList.map(item => {
          return {
            id: item.id,
            styleId: 'carStyle',
            position: new TMap.LatLng(item.lat, item.lng),
            properties: {
              //自定义属性
              plate_number: item.plate_number,
              driver_name: item.driver_name
            }
          }
        })
      ]
       this.carMarker.setGeometries(geometries)
      // this.carMarker = new TMap.MultiMarker({
      //   map: this.map, //指定地图容器
      //   //样式定义
      //   styles: {
      //     carStyle: new TMap.MarkerStyle({
      //       width: 64, // 点标记样式宽度（像素）
      //       height: 64, // 点标记样式高度（像素）
      //       src: carIcon, //图片路径
      //       //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      //       anchor: { x: 16, y: 32 }
      //     })
      //   },
      //   //点标记数据数组
      //   geometries: geometries
      //   // [
      //   //   ...this.carList.map(item => {
      //   //     return {
      //   //       id: item.id,
      //   //       styleId: 'carStyle',
      //   //       position: new TMap.LatLng(item.lat, item.lng),
      //   //       properties: {
      //   //         //自定义属性
      //   //         plate_number: item.plate_number,
      //   //         driver_name: item.driver_name
      //   //       }
      //   //     }
      //   //   })
      //   // ]
      // })
      this.carMarker.on('mouseover', evt => {
        //设置infoWindow
        this.infoWindow.open() //打开信息窗
        this.infoWindow.setPosition(evt.geometry.position) //设置信息窗位置
        this.infoWindow.setContent(`<div><div style="font-size:16px">${evt.geometry.properties.plate_number}</div></div>`) //设置信息窗内容
      })
      this.carMarker.on('mouseout', evt => {
        this.infoWindow.close()
      })
    },
    batchAddToiletMarks() {
      let geometries = [
        ...this.toiletList.map(item => {
          return {
            id: item.id,
            styleId: 'toiletStyle',
            position: new TMap.LatLng(item.lat, item.lng),
            properties: {
              //自定义属性
              name: item.name,
              status: item.status
            }
          }
        })
      ]
      this.toiletMarker.setGeometries(geometries)
      // this.toiletMarker = new TMap.MultiMarker({
      //   map: this.map, //指定地图容器
      //   //样式定义
      //   styles: {
      //     //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
      //     toiletStyle: new TMap.MarkerStyle({
      //       width: 56, // 点标记样式宽度（像素）
      //       height: 56, // 点标记样式高度（像素）
      //       src: toiletIcon, //图片路径
      //       //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      //       anchor: { x: 30, y: 32 }
      //     })
      //   },
      //   //点标记数据数组
      //   geometries: geometries
      //   // [
      //   //   ...this.toiletList.map(item => {
      //   //     return {
      //   //       id: item.id,
      //   //       styleId: 'toiletStyle',
      //   //       position: new TMap.LatLng(item.lat, item.lng),
      //   //       properties: {
      //   //         //自定义属性
      //   //         name: item.name,
      //   //         status: item.status
      //   //       }
      //   //     }
      //   //   })
      //   // ]
      // })
      // console.log('@',this.toiletMarker.getGeometries());
      this.toiletMarker.updateGeometries()
      this.toiletMarker.on('mouseover', evt => {
        //设置infoWindow
        this.infoWindow.open() //打开信息窗
        this.infoWindow.setPosition(evt.geometry.position) //设置信息窗位置
        this.infoWindow.setContent(
          `<div style="text-align:left;"><div style="font-size:18px;color:#00A3FF">${
            evt.geometry.properties.name
          }</div><div style="font-size:14px;color:#fff">状态：<span style="color:${evt.geometry.properties.status == 20 ? '#29F1FA' : '#FF7D00'}">${
            evt.geometry.properties.status == 20 ? '正常' : '待抽取'
          }</span></div></div>`
        ) //设置信息窗内容
      })
      this.toiletMarker.on('mouseout', evt => {
        this.infoWindow.close()
      })
    },
    reset() {
      this.map.setCenter(new TMap.LatLng(this.lat, this.lng))
      this.map.setZoom(13)
    },
    loadScript() {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = 'https://map.qq.com/api/gljs?v=1.exp&key=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77&callback=init'
      script.onload = script.onreadystatechange = () => {
        if (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete') {
          this.initMap()
          script.onload = script.onreadystatechange = null
        }
      }
      document.body.appendChild(script)
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
}
</style>
