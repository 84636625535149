import { http } from '@/http/axios'
// 车辆列表
export function getCarListAPI(params) {
  return http({
    url: '/manage/car/indexList',
    method: 'get',
    params
  })
}
// 订单
export function getOrderListAPI(params) {
  return http({
    url: '/manage/carList/orderList',
    method: 'get',
    params
  })
}
// 30天统计
export function getMonthCountAPI(params) {
  return http({
    url: '/manage/carList/staticList',
    method: 'get',
    params
  })
}
// 改厕统计
export function getCarStaticAPI(params) {
  return http({
    url: '/manage/carList/static',
    method: 'get',
    params
  })
}
export function getToiletListAPI(params) {
  return http({
    url: '/manage/toilet/indexList',
    method: 'get',
    params
  })
}
