import axios from 'axios'
let baseURL = '/api'
import { Message } from 'element-ui'
import { getToken, removeToken } from '@/utils/auth'
import { delEmptyQueryNodes } from '@/utils/index'
import { LOGIN_INVALID_CODES } from '@/enum/login.js'
import { router } from '@/router'
import { showScreenLoading, hideScreenLoading } from "./loading";

const service = axios.create({
    withCredentials: true,
    crossDomain: true,
    baseURL,
    timeout: 60000
})

// request拦截器,在请求之前做一些处理
service.interceptors.request.use(
    config => {
        // config.headers['Authorization'] = getToken() || ''
        if (config.headers.showLoading !== false) {
            showScreenLoading(config.headers);
        }
        return config;
    },
    error => {
        console.log(error); // for debug
        if (error.headers.showLoading !== false) {
            hideScreenLoading(error.headers);
        }
        Message.error("请求超时!");
        return Promise.reject(error);
    }
);

//配置成功后的拦截器
service.interceptors.response.use(async res => {
    if (res.status == 200) {
        setTimeout(() => {
            if (res.config.headers.showLoading !== false) {
                hideScreenLoading();
            }
        }, 0)
        
        let { code } = res.data
        if (code == '10000') return res.data.data
        else if (LOGIN_INVALID_CODES.indexOf(code) > -1) {
            removeToken()
            // router.push('/login')
            return Promise.reject(res)
        } else {
            return Promise.reject(res)
        }
    } else {
        return Promise.reject(res);
    }
}, error => {
    return Promise.reject(error)
})


async function http(requestParams, isShowError = true) {
    try {
        if (requestParams && requestParams.params) {
            requestParams.params = delEmptyQueryNodes(requestParams.params)
        }
        let res = await service(requestParams)
        return res
    } catch (error) {
        console.error(error)
        hideScreenLoading();
        isShowError && Message.error(error?.data?.msg || error?.data?.message || '系统异常')
        return Promise.reject(error)
    }
}


export {
    http,
    service,
}